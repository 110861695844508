export const raumverwaltung = {
  headerTitle: 'Raumverwaltung',
  route: 'raumverwaltung',
  pageTitle: 'paedML - Raumverwaltung',
  related: [
    { route: 'meine/schuelerinnen', menuTitle: 'Meine Schüler*innen anschauen' },
  ],
  cards: [

    {
      route: 'raum',
      icon: 'raum',
      endpoint: '/api/rooms',
      endpoints: {
      },
      preHooks: {
        list: 'raum_list_preHook'
      },
      tag: 'paedml-card-raum',
      category: 'Raumverwaltung',
      menuTitle: 'Räume',
      pageTitle: 'paedML - Räume',
      name: 'raum',
      contentTitle: 'Räume',
      prefix: 'raum',
      suffix: undefined,
      custom: {
        variant: 'raum'
      },
      table: {
        columns: [
          { title: 'ID', name: 'id', col: 0, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Name', name: 'name', col: 1 },
          { title: 'Schularten', name: 'schoolTypes', col: 2, separator: ', ', filter: 'Schulart', filterType: 'regex' },
          { title: 'Raumstandard: Internet-Sperre', name: 'defaultInternetLock', col: 3 },
          { title: 'Raumstandard: Computer-Sperre', name: 'defaultComputerLock', col: 4 },
          { title: 'Computer', name: 'computersDP', col: 5 },
          { title: 'computers', name: 'computers', col: 6, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Drucker', name: 'printersDP', col: 7 },
          { title: 'printers', name: 'printers', col: 8, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Standard-Drucker', name: 'defaultPrinterDP', col: 9 },
          { title: 'defaultPrinter', name: 'defaultPrinter', col: 10, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'BSA aktiv', name: 'isBsaEnabled', col: 11 },
          { title: 'BSA Schulart', name: 'bsaSchoolDP', col: 12 },
          { title: 'BSA Klasse', name: 'bsaSchoolClassDP', col: 13 },
          { title: 'Lehrerin angemeldet', name: 'isTeacherLoggedIn', col: 14 },
          { title: 'bsaSchool', name: 'bsaSchool', col: 15, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'bsaSchoolClass', name: 'bsaSchoolClass', col: 16, visible: false, searchable: false, printable: false, exportable: false },
        ],
        data: {
          url: '/data/schueler.json',
          dataSrc: ''
        },
        custom: {
          pageLength: 10
        }
      }
    },

    {
      route: 'computer',
      icon: 'geraete-aio',
      endpoint: '/api/computers',
      endpoints: {
      },
      preHooks: {
        list: 'devices_list_preHook'
      },
      tag: 'paedml-card-raum',
      category: 'Raumverwaltung',
      menuTitle: 'Computer',
      pageTitle: 'paedML - Computer',
      name: 'computer',
      contentTitle: 'Computer',
      prefix: 'computer',
      suffix: undefined,
      custom: {
        variant: 'computer'
      },
      table: {
        columns: [
          { title: 'ID', name: 'id', col: 0, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Name', name: 'name', col: 1 },
          { title: 'Raum', name: 'roomDP', group: 'simple', col: 2, priority: 1, filter:"Raum", dynamicfilter: "rooms", filterType: 'regex' },
          { title: 'room', name: 'room', col: 3, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'IP', name: 'ipAddress', col: 4 },
          { title: 'Angemeldeter Benutzer', name: 'username', col: 5, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Angemeldeter Benutzer', name: 'displayName', col: 6 },
          { title: 'An', name: 'isOnline', col: 7 },
          { title: 'Gesperrt', name: 'isBlocked', col: 8 },
          { title: 'Internet deaktiviert', name: 'isInternetLocked', col: 9 },
          { title: 'Beschreibung', name: 'description', col: 10, searchable: false }
        ],
        data: {
          url: '/data/schueler.json',
          dataSrc: ''
        },
        custom: {
          pageLength: 10,
          order: [[ 2, 'asc' ], [ 1, 'asc' ]],
          select: {
            style: 'multi+shift'
          }
        }
      }
    },

    {
      route: 'drucker',
      icon: 'drucker-entsperrt',
      endpoint: '/api/printers',
      endpoints: {
      },
      preHooks: {
        list: 'devices_list_preHook'
      },
      tag: 'paedml-card-raum',
      category: 'Raumverwaltung',
      menuTitle: 'Drucker',
      pageTitle: 'paedML - Drucker',
      name: 'drucker',
      contentTitle: 'Drucker',
      prefix: 'drucker',
      suffix: undefined,
      custom: {
        variant: 'drucker'
      },
      table: {
        columns: [
          { title: 'ID', name: 'id', col: 0, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Name', name: 'name', col: 1 },
          { title: 'Raum', name: 'roomDP', col: 2, group: 'simple', priority: 1, filter:"Raum", dynamicfilter: "rooms", filterType: 'regex' },
          { title: 'room', name: 'room', col: 3, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'IP', name: 'ipAddress', col: 4 },
          { title: 'An', name: 'isOnline', col: 5, searchable: false },
          { title: 'Standard-Drucker', name: 'isDefaultPrinter', col: 6, searchable: false },
          { title: 'Verwaltung erlaubt', name: 'isManagementAllowed', col: 7, searchable: false },
          { title: 'Raumstandard: Drucken erlaubt', name: 'defaultPrintingAllowed', col: 8, searchable: false },
          { title: 'Aktuell: Drucken erlaubt', name: 'isPrintingAllowed', col: 9, searchable: false },
        ],
        data: {
          url: '/data/schueler.json',
          dataSrc: ''
        },
        custom: {
          pageLength: 10,
          order: [[ 2, 'asc' ], [ 1, 'asc' ]],
        }
      }
    },

    {
      route: 'bsa',
      icon: 'far-id-badge',
      endpoint: '/api/bsa',
      endpoints: {
      },
      preHooks: {
        list: 'bsa_list_preHook'
      },
      tag: 'paedml-card-bsa',
      category: 'Raumverwaltung',
      menuTitle: 'BSA-Anfragen',
      pageTitle: 'paedML - BSA',
      name: 'bsa',
      contentTitle: 'BSA-Anfragen',
      prefix: 'bsa',
      suffix: undefined,
      custom: {
        variant: 'bsa'
      },
      table: {
        columns: [
          { title: '', name: 'icon', col: 0, priority: 10, searchable: false, printable: false, exportable: false, orderable: false, fnCreatedCell: (td, val) => val ? $(td).html(`<svg class="paedml-icon black" style="margin-top: -3px"><use xlink:href="#${val}" /></svg>`) : '' },
          { title: 'ID', name: 'id', col: 1, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Benutzername', name: 'userName', col: 2 },
          { title: 'Nachname', name: 'surname', col: 3 },
          { title: 'Vorname', name: 'givenName', col: 4 },
          { title: 'Schulart', name: 'schoolTypeDP', col: 5, filter: 'Schulart', filterType: 'regex' },
          { title: 'schoolType', name: 'schoolType', col: 6, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Klasse', name: 'schoolClassDP', col: 7 },
          { title: 'schoolClass', name: 'schoolClass', col: 8, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'isDuplicate', name: 'isDuplicate', col: 9, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Vermerk', name: 'vermerk', col: 10, filter:'VermerkBSA', filtertitle: 'Vermerk', printable: false, exportable: false },
        ],
        data: {
          url: '/data/schueler.json',
          dataSrc: ''
        },
        custom: {
          pageLength: 10,
          order: [[ 3, 'asc' ], [ 4, 'asc' ]],
          select: {
            style: 'multi+shift'
          }
        }
      }
    },
  ]
}
