export const firewallverwaltung = {
  headerTitle: 'Firewallverwaltung',
  route: 'firewallverwaltung',
  pageTitle: 'paedML - Firewallverwaltung',
  related: [
    { route: 'benutzerverwaltung/schularten', menuTitle: 'Schularten verwalten' },
    { route: 'benutzerverwaltung/klassen', menuTitle: 'Klassen verwalten' },
    { route: 'meine/projekte', menuTitle: 'Projekte verwalten' },
  ],
  cards: [

    {
      route: 'templates',
      icon: 'far-clone',
      endpoint: '/api/firewall/schoolTypes',
      endpoints: {
      },
      preHooks: {
        list: 'fw_templates_list_preHook'
      },
      tag: 'paedml-card-firewall',
      category: 'Firewallverwaltung',
      menuTitle: 'Vorlagen',
      pageTitle: 'paedML - Firewall-Vorlagen',
      name: 'fwtemplate',
      contentTitle: 'Vorlagen',
      prefix: 'fwtemplate',
      suffix: undefined,
      custom: {
        variant: 'schularten'
      },
      table: {
        columns: [
          { title: 'ID', name: 'id', col: 0, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'schoolType', name: 'schoolType', col: 1, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Schulart', name: 'schoolTypeDP', col: 2, group: 'simple', visible: false, printable: false, exportable: false },
          { title: 'Firewall-Kategorien', name: 'firewallCategoriesDP', className: 'paedml-nowrap', col: 3, fnCreatedCell: (td, val) => val ? $(td).html(val) : $(td).html('«LEER»') },
          { title: 'firewallCategories', name: 'firewallCategories', col: 4, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Blacklist', name: 'blacklistDP', className: 'paedml-nowrap', col: 5, fnCreatedCell: (td, val) => val ? $(td).html(val) : $(td).html('«LEER»') },
          { title: 'blacklist', name: 'blacklist', col: 6, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Blacklist aktiv', name: 'blacklistActive', col: 7, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Whitelist', name: 'whitelistDP', className: 'paedml-nowrap', col: 8, fnCreatedCell: (td, val) => val ? $(td).html(val) : $(td).html('«LEER»') },
          { title: 'whitelist', name: 'whitelist', col: 9, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Whitelist aktiv', name: 'whitelistActive', col: 10, visible: false, searchable: false, printable: false, exportable: false },
        ],
        data: {
          url: '/data/fw-settings.json',
          dataSrc: ''
        },
        custom: {
        }
      }
    },

    {
      route: 'global',
      icon: 'far-list',
      endpoint: '/api/firewall/global',
      endpoints: {
      },
      preHooks: {
        list: 'fw_global_list_preHook'
      },
      tag: 'paedml-card-firewall',
      category: 'Firewallverwaltung',
      menuTitle: 'Globale Filterliste',
      pageTitle: 'paedML - Firewall-Globale Filterliste',
      name: 'fwglobal',
      contentTitle: 'Globale Filterliste',
      prefix: 'fwglobal',
      suffix: undefined,
      custom: {
        variant: 'global'
      },
      table: {
        columns: [
          { title: 'ID', name: 'id', col: 0, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'schoolType', name: 'schoolType', col: 1, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Schulart', name: 'schoolTypeDP', col: 2, group: 'simple', visible: false, printable: false, exportable: false },
          { title: 'Firewall-Kategorien', name: 'firewallCategoriesDP', visible: false, className: 'paedml-nowrap', col: 3, fnCreatedCell: (td, val) => val ? $(td).html(val) : $(td).html('«LEER»') },
          { title: 'firewallCategories', name: 'firewallCategories', col: 4, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Blacklist', name: 'blacklistDP', className: 'paedml-nowrap', col: 5, fnCreatedCell: (td, val) => val ? $(td).html(val) : $(td).html('«LEER»') },
          { title: 'blacklist', name: 'blacklist', col: 6, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Blacklist aktiv', name: 'blacklistActive', col: 7, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Whitelist', name: 'whitelistDP', className: 'paedml-nowrap', col: 8, fnCreatedCell: (td, val) => val ? $(td).html(val) : $(td).html('«LEER»') },
          { title: 'whitelist', name: 'whitelist', col: 9, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Whitelist aktiv', name: 'whitelistActive', col: 10, visible: false, searchable: false, printable: false, exportable: false },
        ],
        data: {
          url: '',
          dataSrc: ''
        },
        custom: {
        }
      }
    },


    {
      route: 'klassen',
      icon: 'klasse',
      endpoint: '/api/firewall/schoolClasses',
      endpoints: {
      },
      preHooks: {
        list: 'fw_klassen_list_preHook'
      },
      tag: 'paedml-card-firewall',
      category: 'Firewallverwaltung',
      menuTitle: 'Klassen',
      pageTitle: 'paedML - Firewall-Klassen',
      name: 'fwklassen',
      contentTitle: 'Klassen',
      prefix: 'fwklassen',
      suffix: undefined,
      custom: {
        variant: 'klassen'
      },
      table: {
        columns: [
          { title: 'ID', name: 'id', col: 0, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Klasse', name: 'schoolClassDP', col: 1 },
          { title: 'Schulart', name: 'schoolTypeDP', col: 2, group: 'simple', visible: false, printable: false, exportable: false, filter: 'Schulart', filterType: 'regex' },
          { title: 'Firewall-Kategorien', name: 'firewallCategoriesDP', className: 'paedml-nowrap', col: 3, fnCreatedCell: (td, val) => val ? $(td).html(val) : $(td).html('«LEER»') },
          { title: 'firewallCategories', name: 'firewallCategories', col: 4, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Blacklist', name: 'blacklistDP', className: 'paedml-nowrap', col: 5, fnCreatedCell: (td, val) => val ? $(td).html(val) : $(td).html('«LEER»') },
          { title: 'blacklist', name: 'blacklist', col: 6, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Blacklist aktiv', name: 'blacklistActive', col: 7, searchable: false, printable: false, exportable: false },
          { title: 'Whitelist', name: 'whitelistDP', className: 'paedml-nowrap', col: 8, fnCreatedCell: (td, val) => val ? $(td).html(val) : $(td).html('«LEER»') },
          { title: 'whitelist', name: 'whitelist', col: 9, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Whitelist aktiv', name: 'whitelistActive', col: 10, searchable: false, printable: false, exportable: false },
          { title: 'Nur Whitelist verwenden', name: 'whitelistOnly', col: 11, searchable: false, printable: false, exportable: false },
          { title: 'schoolClass', name: 'schoolClass', col: 12, visible: false, searchable: false, printable: false, exportable: false },
        ],
        data: {
          url: '/data/fw-settings.json',
          dataSrc: ''
        },
        custom: {
        }
      }
    },
  ]
}
