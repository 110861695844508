export const datatables = {
    "sEmptyTable":      "Keine Daten in der Tabelle vorhanden",
    "sInfo":            "Zeige _START_ bis _END_ von <b>_TOTAL_</b> Einträgen",
    "sInfoEmpty":       "Keine Daten vorhanden",
    "sInfoFiltered":    "<b class='text-danger'>(gefiltert von insg. _MAX_ Einträgen)</b>",
    "sInfoPostFix":     ". ",
    "sInfoThousands":   ".",
    "sLengthMenu":      "_MENU_ Einträge anzeigen",
    "sLoadingRecords":  "Wird geladen ..",
    "sProcessing":      "Bitte warten ..",
    "sSearch":          "Suchen",
    "sZeroRecords":     "Keine Einträge vorhanden",
    "oPaginate": {
        "sFirst":       "Erste",
        "sPrevious":    "Zurück",
        "sNext":        "Nächste",
        "sLast":        "Letzte"
    },
    "oAria": {
        "sSortAscending":  ": aktivieren, um Spalte aufsteigend zu sortieren",
        "sSortDescending": ": aktivieren, um Spalte absteigend zu sortieren"
    },
    "select": {
        "rows": {
            "_": "<b class='text-info'>%d Zeilen ausgewählt</b>",
            "0": "",
            "1": "<b class='text-info'>1 Zeile ausgewählt</b>"
        }
    },
    "buttons": {
        "print":    "Drucken",
        "colvis":   "Spalten",
        "copy":     "Kopieren",
        "copyTitle":    "In Zwischenablage kopieren",
        "copyKeys": "Taste <i>ctrl</i> oder <i>\u2318</i> + <i>C</i> um Tabelle<br>in Zwischenspeicher zu kopieren.<br><br>Um abzubrechen die Nachricht anklicken oder Escape drücken.",
        "copySuccess": {
            "_": "%d Spalten kopiert",
            "1": "1 Spalte kopiert"
        }
    }
}

export const short = {
    "sEmptyTable":      "Keine Daten in der Tabelle vorhanden",
    "sInfo":            "Zeige _TOTAL_",
    "sInfoEmpty":       "Zeige 0",
    "sInfoFiltered":    " von _MAX_",
    "sInfoPostFix":     " Zeile(n). ",
    "sInfoThousands":   ".",
    "sLengthMenu":      "_MENU_ Einträge anzeigen",
    "sLoadingRecords":  "Wird geladen ..",
    "sProcessing":      "Bitte warten ..",
    "sSearch":          "Suchen",
    "sZeroRecords":     "Keine Einträge vorhanden",
    "oPaginate": {
        "sFirst":       "Erste",
        "sPrevious":    "Zurück",
        "sNext":        "Nächste",
        "sLast":        "Letzte"
    },
    "oAria": {
        "sSortAscending":  ": aktivieren, um Spalte aufsteigend zu sortieren",
        "sSortDescending": ": aktivieren, um Spalte absteigend zu sortieren"
    },
    "select": {
        "rows": {
            "_": "<b class='text-info'>%d Zeilen ausgewählt</b>",
            "0": "",
            "1": "<b class='text-info'>1 Zeile ausgewählt</b>"
        }
    },
    "buttons": {
        "print":    "Drucken",
        "colvis":   "Spalten",
        "copy":     "Kopieren",
        "copyTitle":    "In Zwischenablage kopieren",
        "copyKeys": "Taste <i>ctrl</i> oder <i>\u2318</i> + <i>C</i> um Tabelle<br>in Zwischenspeicher zu kopieren.<br><br>Um abzubrechen die Nachricht anklicken oder Escape drücken.",
        "copySuccess": {
            "_": "%d Spalten kopiert",
            "1": "1 Spalte kopiert"
        }
    }
}

export const datetimepicker = {
    "previous": "Zurück",
    "next":    "Nächste",
    "months":   [ "Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "October", "November", "Dezember" ],
    "weekdays": [ "So.", "Mo.", "Di.", "Mi.", "Do.", "Fr.", "Sa." ],
    "clear":    "Löschen",
    "hours":    "Stunden",
    "minutes":  "Minuten",
    "seconds":  "Sekunden",
    "today":    "Heute",
    "unknown":  "Unbekannt",
    "days": ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"],
    "monthsShort": ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
}