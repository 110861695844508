export const klassenarbeiten = {
  headerTitle: 'Klassenarbeiten',
  route: 'klassenarbeiten',
  pageTitle: 'paedML - Klassenarbeiten',
  related: [
    { route: 'meine/klassen', menuTitle: 'Meine Klassen' },
    { route: 'meine/schuelerinnen', menuTitle: 'Meine Schüler*innen' },
  ],
  cards: [

    {
      route: 'klassenarbeiten',
      icon: 'fas-laptop-code',
      endpoint: '/api/exams',
      endpoints: {
      },
      preHooks: {
        list: 'exam_list_preHook'
      },
      tag: 'paedml-card-exam',
      category: 'Klassenarbeiten',
      menuTitle: 'Klassenarbeiten verwalten',
      pageTitle: 'paedML - Klassenarbeiten',
      name: 'exam',
      contentTitle: 'Klassenarbeiten',
      prefix: 'exam',
      suffix: undefined,
      custom: {
        variant: 'exam'
      },
      left: 7,
      table: {
        columns: [
          { title: 'ID', name: 'id', col: 0, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Titel', name: 'titleDP', col: 1, group: 'simple', visible: false, printable: false, exportable: false },
          { title: 'title', name: 'title', col: 2, visible: false, filter: 'KA-Titel', dynamicfilter: 'kaTitle', filterType: 'regex' },
          { title: 'Beschreibung', name: 'description', col: 3, visible: false, priority: 6 },
          { title: 'Erstellt am', name: 'createdUF', col: 4, orderData: [4], priority: 9 },
          { title: 'created', name: 'created', col: 5, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Aktiv', name: 'enabled', col: 6, filter: "Aktiv", priority: 8 },
          { title: 'Kennwort', name: 'password', col: 7, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Leitung', name: 'leadersDP', className: 'paedml-nowrap', col: 8, priority: 9, visible: true, searchable: false, printable: false, exportable: false },
          { title: 'Leitung', name: 'leadersP', col: 9, visible: false, searchable: true, printable: true, exportable: true },
          { title: 'leaders', name: 'leaders', col: 10, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Teilnehmer', name: 'membersDP', className: 'paedml-nowrap', col: 11, priority: 10, fnCreatedCell: (td, val) => val ? $(td).html(val) : $(td).html('«LEER»'), visible: true, searchable: false, printable: false, exportable: false },
          { title: 'Teilnehmer', name: 'membersP', col: 12, visible: false, searchable: true, printable: true, exportable: true  },
          { title: 'members', name: 'members', col: 13, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Whitelist', name: 'whitelistDP', className: 'paedml-nowrap', col: 14, priority: 8, fnCreatedCell: (td, val) => val ? $(td).html(val) : $(td).html('«LEER»') },
          { title: 'whitelist', name: 'whitelist', col: 15, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Whitelist aktiv', name: 'whitelistActive', col: 16, priority: 8, searchable: false, printable: false, exportable: false },
          { title: 'Nur Whitelist verwenden', name: 'whitelistOnly', col: 17, visible: false, searchable: false, printable: false, exportable: false },
        ],
        data: {
          dataSrc: ''
        },
        custom: {
          pageLength: 10,
          order: [[ 5, 'desc' ]],
          select: {
            style: 'os'
          },
        }
      }
    },

    {
      route: 'protokolle',
      icon: 'fas-history',
      endpoint: '/api/exams/history',
      endpoints: {
      },
      preHooks: {
        list: 'examlogs_list_preHook'
      },
      tag: 'paedml-card-kalogs',
      category: 'Klassenarbeiten',
      menuTitle: 'Protokolle einsehen',
      pageTitle: 'paedML - Protokolle einsehen',
      name: 'history',
      contentTitle: 'Protokolle',
      prefix: 'history',
      suffix: undefined,
      custom: {
        variant: 'history'
      },
      left: 7,
      table: {
        columns: [
          { title: 'ID', name: 'id', col: 0, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Titel', name: 'examDP', col: 1, filter: 'KA-Titel', dynamicfilter: 'kaTitle', filterType: 'regex' },
          { title: 'exam', name: 'exam', col: 2, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Eintrag am', name: 'createdUF', col: 3, orderData: [0] },
          { title: 'created', name: 'created', col: 4, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'reserved', col: 5, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Benutzer', name: 'userDP', col: 6, className: 'paedml-nowrap' },
          { title: 'member', name: 'member', col: 7, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'KA-Benutzer', name: 'kaUserDP', col: 8, priority: 9 },
          { title: 'reserved', col: 9, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Aktion', name: 'actionDP', col: 10, priority: 10 },
          { title: 'Computer', name: 'computerDP', col: 11, priority: 2 },
          { title: 'Raum', name: 'roomDP', col: 12, priority: 1 },

        ],
        data: {
          dataSrc: ''
        },
        custom: {
          pageLength: 10,
          select: {
            style: 'os'
          },
        }
      }
    },

  ]
}