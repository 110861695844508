import * as R from 'ramda'


export const dashboard = {
  headerTitle: 'Dashboard',
  route: 'dashboard',
  pageTitle: 'paedML - Dashboard',
  cards: [
    {
      route: 'home',
      customAPI: 'home',
      tag: 'paedml-card-dashboard',
      category: 'Dashboard',
      menuTitle: 'Home',
      pageTitle: 'paedML - Dashboard: Home',
      name: 'home',
      contentTitle: 'Home',
      prefix: 'home',
      suffix: 'home',
      representation: 'tiles-home',
      options: {
        showBigIcons: true
      },
      splitBreak: 'md',
      left: 9,
      table: {
        columns: [
          { title: 'ID', name: 'id', col: 0, visible: false, searchable: false, printable: false, exportable: false},
          { title: 'pseudoId', name: 'pseudoId', col: 1, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'URL', name: 'url', col: 2, fnCreatedCell: (td, val) => $(td).html(`<a href="/#${val}"><svg class="paedml-icon black"><use xlink:href="#far-share-square" /></svg> ${val}</a>`) },
          { title: 'Kategorie', name: 'category', col: 3 },
          { title: 'Titel', name: 'contentTitle', col: 4 },
          { title: 'Favorit', name: 'favorite', filter: 'Favoriten'}
        ],
        data: {
          url: '/data/home.json',
          dataSrc: ''
        },
        custom: {
          pageLength: 10,
        }
      }
    },

    {
      route: 'fav',
      customAPI: 'fav',
      tag: 'paedml-card-dashboard',
      category: 'Dashboard',
      menuTitle: 'Favoriten',
      pageTitle: 'paedML - Dashboard: Favoriten',
      name: 'fav',
      contentTitle: 'Favoriten',
      prefix: 'fav',
      suffix: 'fav',
      representation: 'tiles',
      table: {
        columns: [
          { title: 'ID', name: 'id', col: 0, visible: false, searchable: false, printable: false, exportable: false},
          { title: 'pseudoId', name: 'pseudoId', col: 1, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'URL', name: 'url', col: 2, fnCreatedCell: (td, val) => $(td).html(`<a href="/#${val}"><svg class="paedml-icon black"><use xlink:href="#far-share-square" /></svg> ${val}</a>`) },
          { title: 'Kategorie', name: 'category', col: 3 },
          { title: 'Titel', name: 'contentTitle', col: 4 },
        ],
        data: {
          url: '/data/fav.json',
          dataSrc: ''
        },
        custom: {
          pageLength: 10,
        }
      }
    },

    {
      route: 'all',
      customAPI: 'all',
      tag: 'paedml-card-dashboard',
      category: 'Dashboard',
      menuTitle: 'Favoriten verwalten',
      pageTitle: 'paedML - Dashboard: Favoriten verwalten',
      name: 'all',
      contentTitle: 'Favoriten verwalten',
      prefix: 'all',
      suffix: 'all',
      representation: 'tiles',
      splitBreak: 'md',
      left: 9,
      table: {
        columns: [
          { title: 'ID', name: 'id', col: 0, visible: false, searchable: false, printable: false, exportable: false},
          { title: 'pseudoId', name: 'pseudoId', col: 1, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'URL', name: 'url', col: 2, fnCreatedCell: (td, val) => $(td).html(`<a href="/#${val}"><svg class="paedml-icon black"><use xlink:href="#far-share-square" /></svg> ${val}</a>`) },
          { title: 'Kategorie', name: 'category', col: 3 },
          { title: 'Titel', name: 'contentTitle', col: 4 },
          { title: 'Favorit', name: 'favorite', col: 5, fnCreatedCell: (td, val) => $(td).html(`<svg class="paedml-icon black"><use xlink:href="#fa${val ? 's' : 'r'}-star" /></svg>`) },
          { title: 'Type', name: 'type', col: 6, visible: false, searchable: false, printable: false, exportable: false },
        ],
        data: {
          url: '/data/all.json',
          dataSrc: ''
        },
        custom: {
          pageLength: 10,
        }
      }
    },


  ]
}

