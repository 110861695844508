import axios from 'axios'
import qs from 'qs'
import { LocalStorageAPI, SessionStorageAPI } from './storage'
import * as R from 'ramda'

const getBaseUrl = () => {
    switch(window.location.hostname)
    {
        case 'localhost':
        case 'schulkonsole':
            return `https://${window.location.hostname}:43001`
        //case 'octogate':
                // TODO: 
        default:
           var regex = /(?:http[s]*\:\/\/)*(.*?)\.(?=[^\/]*\..{2,5})/i
           var result = window.location.origin.match(regex)
           return R.replace(result[1],'skservice', window.location.origin)
    }
}

const withDefaultOpts = R.mergeRight({
    baseURL: getBaseUrl(),
    timeout: 60000
})

const withDefaultOptsForToken = R.mergeRight({
    baseURL: getBaseUrl(),
    timeout: 60000
})

export const createRequestCanceler = () => axios.CancelToken.source()

const createClient = (opt = {}, tokenOpt = {}) => {
    let context = {}
    
    const _http = axios.create(withDefaultOpts(opt))

    // dedicated one for token
    const _httpToken = axios.create(withDefaultOptsForToken(tokenOpt))
    _httpToken.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
    
    const logout = () => {
        clearToken()
    }
    
    const rejectionRefreshTokenNotFound = () => Promise.reject(
        { response: {
            data: 'no refresh token found. please re-login', 
            status: 404}
        }
    )

    const login = () => {
        const token = SessionStorageAPI.get('token');
        if (token) {
            setupUsingToken(token)
            return Promise.resolve(
                token.userDetail
            )
        }
    
        const refreshToken = LocalStorageAPI.get('refreshToken')
    
        return loginWithRefreshToken(refreshToken)
    }
    
    const loginWithRefreshToken = refreshToken => {
        if (!refreshToken) return rejectionRefreshTokenNotFound()

        return _httpToken.post('/token', qs.stringify({
                grant_type: 'refresh_token',
                refresh_token: refreshToken
              })).then(handleLoginSuccess, handleLoginError)
    }

    const loginWithUsernameAndPassword = (username, password) => {
        
        return _httpToken.post('/token', qs.stringify({
            grant_type: 'password',
            username,
            password
        })).then(handleLoginSuccess, handleLoginError)
    }

    const handleLoginSuccess = res => {
        return R.pipe(
            R.prop('data'),
            toToken,
            setupUsingToken,
            R.prop('userDetail')
        )(res)
    }
    
    const handleLoginError = res => {
        return   Promise.reject(handleError(res))
    }
    
    const setToken = ({accessToken, tokenType, expiresAt, refreshToken}) => {
        context = {
            accessToken, 
            tokenType, 
            expiresAt, 
            refreshToken
        }
    }

    const toToken = (data) => {
        const userDetail = JSON.parse(data.user)
        userDetail.profile = data.profileProperties ? JSON.parse(data.profileProperties) : { favorites: [] }
        const token = {
            accessToken: data.access_token,
            tokenType: data.token_type,
            refreshToken: data.refresh_token,
            userDetail,
            expiresAt: new Date(new Date().getTime() + data.expires_in * 1000).getTime()
        }
        return token;
    }
    
    const clearToken = () => {
        LocalStorageAPI.remove('refreshToken')
        SessionStorageAPI.remove('token')
        delete _http.defaults.headers.common['Authorization']

        if (typeof $ !== 'undefined') {
            $.ajaxSetup({
                headers: { }
            })
        }
    }
    const setupUsingToken = token => {
        LocalStorageAPI.put('refreshToken', token.refreshToken)
    
        SessionStorageAPI.put('token', token)
        _http.defaults.headers.common['Authorization'] = token.tokenType + ' ' + token.accessToken
        _http.defaults.headers.common['Content-Type'] = 'application/json';
        _httpToken.defaults.headers.common['Authorization'] = token.tokenType + ' ' + token.accessToken

        if (typeof $ !== 'undefined') {
            $.ajaxSetup({
                headers: {
                    Authorization: 'Bearer ' + token.accessToken
                }
            })
        }

        setToken(token)

        return token
    }

    const hasExpired = () => {

        let hasExpired = (context.expiresAt || 0) - new Date().getTime() <= 0
        return hasExpired
    }

    const withStatus = rsp => {
        const success = rsp.status >= 200 && rsp.status < 400
        document.title = document.title.replace(/^[*][ ]/, '')
        $('#avatar').removeClass('be_loading')
        return {
            code: rsp.status,
            text: rsp.text,
            success,
            error: !success
        }
    }

    const handleSuccess = res => [res.data, withStatus(res), res]
    const handleError = res => {
        const timedOut = R.includes('timeout', res.message)
        if (timedOut) {
            Messenger().post({
                message: 'Netzwerk-Zeitüberschreitung!',
                type: 'error'
            })
            document.title = document.title.replace(/^[*]\ paedML/, 'paedML')
            $('#avatar').removeClass('be_loading')
            return ['Netzwerk-Zeitüberschreitung!', {code: 408, text: res.message, success: false, error: true}, res]
        }
        const rsp = res.response || res
        const msgOrData = rsp.message ? rsp.message :
            rsp.data ? (rsp.data.message ? rsp.data.message : rsp.data) : rsp.message ? rsp.message : 'unbekannt'
        return [msgOrData, withStatus(rsp), res]
    }

    const withExpirationCheck = fn => (...args) => {
        document.title = document.title.replace(/^paedML/, '* paedML')
        $('#avatar').addClass('be_loading')
        if (hasExpired()) {
            return loginWithRefreshToken(context.refreshToken || LocalStorageAPI.get('refreshToken'))
                .then(() => fn(...args))
                .then(handleSuccess, handleError)
        }
        return fn(...args).then(handleSuccess, handleError)
    }

    const get = (url, config) => {
        var tmp = _http.get(url, config)
        return tmp
    }

    const post = (url, data, config) => {
        return _http.post(url, data, config)
    }

    const put = (url, data, config) => {
        return _http.put(url, data, config)
    }

    const patch = (url, data, config) => {
        return _http.patch(url, data, config)
    }

    const del = (url, config) => {
        return _http.delete(url, config)
    }

    return {
        login,
        loginWithRefreshToken,
        loginWithUsernameAndPassword,
        logout,

        get: withExpirationCheck(get),
        post: withExpirationCheck(post),
        patch: withExpirationCheck(patch),
        put: withExpirationCheck(put),
        delete: withExpirationCheck(del),
    }
}

export const withPseudoClientResponse = (data, statusCode = 200, message = undefined) => {
    const success = statusCode >= 200 && statusCode < 400
    return Promise.resolve([data, {
        code: statusCode,
        text: message,
        success,
        error: !success
    }, {}])
}

export default createClient
