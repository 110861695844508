const _storageAPI = function (storage) {
    const prefix = 'paedml-'
  
    function get(key) {
      const item = getRaw(key)
      return item ? JSON.parse(item) : undefined
    }
  
    function getRaw(key) {
      return storage.getItem(prefix + key)
    }
  
    function put(key, item) {
      if (! item) {
        console.error('null item supplied for key: ' + key)
        return
      }
      putRaw(key, JSON.stringify(item))
    }
  
    function putRaw(key, item) {
      storage.setItem(prefix + key, item)
    }

    function remove(key) {
      storage.removeItem(prefix + key)
    }
  
    return {
      get,
      getRaw,
      put,
      putRaw,
      remove
    }
  };
  
const _fakeStorage = () => {
  const content = {}
  return {
      getItem: key => content[key],
      setItem: (key, item) => content[key] = item,
      removeItem: key => delete content[key]
  }
}

const sessionStorage = _fakeStorage()
const localStorage = _fakeStorage()

export const SessionStorageAPI = _storageAPI(sessionStorage);

export const LocalStorageAPI = _storageAPI(localStorage);
  