export const wartung = {
  headerTitle: 'Wartung',
  route: 'wartung',
  pageTitle: 'paedML - Wartung',
  related: [
    { route: 'raumverwaltung', menuTitle: 'Raumverwaltung' },
    { route: 'firewallverwaltung/projekte', menuTitle: 'Projekt-Firewall' },
  ],
  cards: [
    {
      route: 'projekte',
      icon: 'fas-project-diagram',
      endpoint: '/api/projects',
      endpoints: {
      },
      preHooks: {
        list: 'projects_list_preHook'
      },
      tag: 'paedml-card-projekte',
      category: 'Wartung',
      menuTitle: 'Projekte verwalten',
      pageTitle: 'paedML - Projekte',
      name: 'projekte',
      contentTitle: 'Projekte',
      prefix: 'projekte',
      suffix: undefined,
      table: {
        columns: [
          { title: 'ID', name: 'id', col: 0, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Name', name: 'name', col: 1 },
          { title: 'Beschreibung', name: 'description', col: 2, priority: 6 },
          { title: 'Leitung', name: 'leadersDP', col: 3, className: 'paedml-nowrap', visible: true, searchable: false, printable: false, exportable: false },
          { title: 'Leitung', name: 'leadersP', col: 4, visible: false, searchable: true, printable: true, exportable: true  },
          { title: 'leaders', name: 'leaders', col: 5, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Mitglieder', name: 'membersDP', className: 'paedml-nowrap', col: 6, priority: 8, visible: true, searchable: false, printable: false, exportable: false },
          { title: 'Mitglieder', name: 'membersP', col: 7, visible: false, searchable: true, printable: true, exportable: true },
          { title: 'members', name: 'members', col: 8, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Tauschlaufwerk', name: 'sharedFolderPath', col: 9, visible: false, searchable: false, printable: false },
          { title: 'Zugriff Tauschlaufwerk', name: 'sharedFolderAccessLevel', col: 10, priority: 7 },
          { title: 'numMembers', name: 'numMembers', col: 11, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Ablaufdatum', name: 'expiryDateDP', col: 12, visible: true, searchable: false, printable: true, exportable: true },
          { title: 'expiryDate', name: 'expiryDate', col: 13, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Ablaufdatum aktiv', name: 'expiryDateActive', col: 14, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'expiryDateDP1', name: 'expiryDateDP1', col: 15, visible: false, searchable: false, printable: false, exportable: true },
        ],
        data: {
          dataSrc: ''
        },
        custom: {
          pageLength: 10
        }
      }
    },

    {
      route: 'ausgeteilte-dateien',
      icon: 'fas-file-export',
      endpoint: '/api/shareFiles',
      endpoints: {
      },
      preHooks: {
        list: 'handout_list_preHook'
      },
      tag: 'paedml-card-handout',
      category: 'Wartung',
      menuTitle: 'Ausgeteilte Dateien verwalten',
      pageTitle: 'paedML - Ausgeteilten Dateien',
      name: 'handout',
      contentTitle: 'Ausgeteilte Dateien',
      prefix: 'handout',
      suffix: undefined,
      custom: {
        variant: 'handout'
      },
      table: {
        columns: [
          { title: 'ID', name: 'id', col: 0, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Name', name: 'name', col: 1 },
          { title: 'Ausgeteilt am', name: 'createdUF', col: 2, orderData: [4] },
          { title: 'created', name: 'created', col: 3, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Ausgeteilt von', name: 'initiatorDP', className: 'paedml-nowrap', col: 4, priority: 7 },
          { title: 'initiator', name: 'initiator', col: 5, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Teilnehmer', name: 'usersDP', className: 'paedml-nowrap', col: 6, visible: true, searchable: false, printable: false, exportable: false },
          { title: 'Teilnehmer', name: 'usersP', col: 7, visible: false, searchable: true, printable: true, exportable: true },
          { title: 'users', name: 'users', col: 8, visible: false, searchable: false, printable: false, exportable: false  },
           ],
        data: {
          dataSrc: ''
        },
        custom: {
          pageLength: 10
        }
      }
    },


    {
      route: 'klassenarbeiten',
      icon: 'fas-laptop-code',
      endpoint: '/api/exams',
      endpoints: {
      },
      preHooks: {
        list: 'exam_list_preHook'
      },
      tag: 'paedml-card-exam',
      category: 'Wartung',
      menuTitle: 'Klassenarbeiten verwalten',
      pageTitle: 'paedML - Klassenarbeiten',
      name: 'exam',
      contentTitle: 'Klassenarbeiten',
      prefix: 'exam',
      suffix: undefined,
      custom: {
        variant: 'exam'
      },
      left: 7,
      table: {
        columns: [
          { title: 'ID', name: 'id', col: 0, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Titel', name: 'titleDP', col: 1, group: 'simple', visible: false, printable: false, exportable: false },
          { title: 'title', name: 'title', col: 2, visible: false, filter: 'KA-Titel', dynamicfilter: 'kaTitle', filterType: 'regex' },
          { title: 'Beschreibung', name: 'description', col: 3, visible: false, priority: 6 },
          { title: 'Erstellt am', name: 'createdUF', col: 4, orderData: [4], priority: 9 },
          { title: 'created', name: 'created', col: 5, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Aktiv', name: 'enabled', col: 6, filter: "Aktiv", priority: 8 },
          { title: 'Kennwort', name: 'password', col: 7, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Leitung', name: 'leadersDP', className: 'paedml-nowrap', col: 8, priority: 9, visible: true, searchable: false, printable: false, exportable: false },
          { title: 'Leitung', name: 'leadersP', col: 9, visible: false, searchable: true, printable: true, exportable: true },
          { title: 'leaders', name: 'leaders', col: 10, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Teilnehmer', name: 'membersDP', className: 'paedml-nowrap', col: 11, priority: 10, fnCreatedCell: (td, val) => val ? $(td).html(val) : $(td).html('«LEER»'), visible: true, searchable: false, printable: false, exportable: false },
          { title: 'Teilnehmer', name: 'membersP', col: 12, visible: false, searchable: true, printable: true, exportable: true  },
          { title: 'members', name: 'members', col: 13, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Whitelist', name: 'whitelistDP', className: 'paedml-nowrap', col: 14, priority: 8, fnCreatedCell: (td, val) => val ? $(td).html(val) : $(td).html('«LEER»') },
          { title: 'whitelist', name: 'whitelist', col: 15, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Whitelist aktiv', name: 'whitelistActive', col: 16, priority: 8, searchable: false, printable: false, exportable: false },
          { title: 'Nur Whitelist verwenden', name: 'whitelistOnly', col: 17, visible: false, searchable: false, printable: false, exportable: false },
        ],
        data: {
          dataSrc: ''
        },
        custom: {
          pageLength: 10,
          select: {
            style: 'os'
          },
        }
      }
    },

    {
      route: 'protokolle',
      icon: 'fas-history',
      endpoint: '/api/exams/history',
      endpoints: {
      },
      preHooks: {
        list: 'examlogs_list_preHook'
      },
      tag: 'paedml-card-kalogs',
      category: 'Wartung',
      menuTitle: 'KA-Protokolle einsehen',
      pageTitle: 'paedML - KA-Protokolle einsehen',
      name: 'history',
      contentTitle: 'KA-Protokolle',
      prefix: 'history',
      suffix: undefined,
      custom: {
        variant: 'history'
      },
      left: 7,
      table: {
        columns: [
          { title: 'ID', name: 'id', col: 0, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Titel', name: 'examDP', col: 1, filter: 'KA-Titel', dynamicfilter: 'kaTitle', filterType: 'regex' },
          { title: 'exam', name: 'exam', col: 2, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Eintrag am', name: 'createdUF', col: 3, orderData: [0] },
          { title: 'created', name: 'created', col: 4, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'reserved', col: 5, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Benutzer', name: 'userDP', col: 6, className: 'paedml-nowrap' },
          { title: 'member', name: 'member', col: 7, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'KA-Benutzer', name: 'kaUserDP', col: 8, priority: 9 },
          { title: 'reserved', col: 9, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Aktion', name: 'actionDP', col: 10, priority: 10 },
          { title: 'Computer', name: 'computerDP', col: 11, priority: 2 },
          { title: 'Raum', name: 'roomDP', col: 12, priority: 1 },

        ],
        data: {
          dataSrc: ''
        },
        custom: {
          pageLength: 10,
          select: {
            style: 'os'
          },
        }
      }
    },


  ]
}